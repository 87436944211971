const React = require("react")
const { renderToString } = require("react-dom/server")
const { JssProvider, SheetsRegistry } = require("react-jss")

export const Wrapper = props => {
  const sheets = new SheetsRegistry()
  console.log("hei ho")

  return (
    <JssProvider registry={sheets}>
      <div id="poppa">{props.children}</div>
    </JssProvider>
  )

}

